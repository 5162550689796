/* eslint-disable @typescript-eslint/no-explicit-any */
import va from '@vercel/analytics'
import config from 'src/config'

// eslint-disable-next-line no-console
const dumy = () => console.info

type Gtag =
  | 'login'
  | 'sign_up'
  | 'dashboard'
  | 'search'
  | 'search_completed'
  | 'view_item'
  | 'select_category'
  | 'begin_checkout'
  | 'add_to_cart'
  | 'refund'
  | 'chat_host'
  | 'add_payment_info'
  | 'purchase'
  | 'share'
  | 'anfitrion_click'
  | 'host_customize'
  | 'host_contact_wa'
  | 'host_start'
  | 'generate_lead'
  | 'host_location'
  | 'host_coordinates'
  | 'host_unit_details'
  | 'host_unit_description'
  | 'host_unit_access'
  | 'host_unit_price'
  | 'host_unit_photos'
  | 'host_unit_submit'
  | 'article_helpful'
  | 'conversion_event_add_to_cart'
  | 'valuation_feedback'

const getGtag = () =>
  typeof window !== 'undefined' && typeof window.gtag === 'function'
    ? (window.gtag ?? dumy)
    : dumy

const getDataLayer = (): any[] =>
  typeof window !== 'undefined' ? ((window as any).dataLayer ?? []) : []

const setUser = (id: string) => {
  const gtag = getGtag()
  getDataLayer().push({ user_id: id })
  gtag('config', config.pixels.ga, { user_id: id })
}

const pageview = (url: URL): void => {
  const gtag = getGtag()
  gtag('config', config.pixels.ga, { page_path: url })
}

const event = (action: Gtag, params?: any): void => {
  const gtag = getGtag()
  gtag('event', action, params)
  va.track(action, params)
}

const ga = { pageview, event, getDataLayer, getGtag, setUser }

export default ga
