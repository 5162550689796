import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import Router from 'next/router'
import React, { useCallback } from 'react'
import fbp from 'src/utils/fbp'
import ga from 'src/utils/gtag'
import TypeWritter from '../../components/TypeWritter'
import {
  IGoogleAutocompleteSuggestion,
  IGooglePlaceDetails
} from '../../types/graphql'
import { RedirectUrls } from '../../types/types'
import { StyledDynamicText } from './styledFirstLoad'
import heroImagePhone from '/public/images/homepage-hero-spotme.gif'

const CustomAutoComplete = dynamic(
  () => import('../../components/AutoComplete/SearchButton')
)

const Hero = () => {
  const { t } = useTranslation('landing')
  const handleOnSearch = useCallback(
    (
      place: IGoogleAutocompleteSuggestion | undefined,
      search: string,
      coords?: IGooglePlaceDetails | undefined
    ) => {
      const { description, placeId } = place ?? {}
      ga.event('search', { search, description })
      fbp.event('FindLocation')
      Router.push({
        pathname: RedirectUrls.Search,
        query: {
          description,
          placeId,
          ...(coords?.lat && coords?.lng
            ? { lat: coords?.lat, lng: coords?.lng }
            : {})
        }
      })
    },
    []
  )

  return (
    <section className="relative flex h-dvh w-full flex-col items-center justify-end overflow-hidden bg-white-500 bg-gradient-to-t from-grey-300 to-white-500 md:max-h-[663px] md:px-5 lg:max-h-[626px] lg:px-7 xl:px-12 sm-md:max-h-[598px] sm-md-tall:pt-[84px]">
      <div className="relative z-10 flex size-full max-w-[1832px] flex-col-reverse md:flex-row md:justify-start sm-md:flex-row">
        <div className="flex w-full flex-col justify-center gap-6 px-5 pb-[30px] pt-[16px] md:w-1/2 md:gap-14 md:pr-0 md:pt-16 lg:pl-0 xl:pl-[5%] sm-md:py-0 sm-md:pr-0 sm-md-tall:h-1/2 sm-md-tall:justify-start">
          <div className="relative flex flex-col">
            <h1 className="pt-4 text-[38px] font-semibold leading-[45px] text-black-400 md:text-[50px] md:leading-[60px]">
              {t('INDEX_HERO.HEADING_FR_1')} <br className="block lg:hidden" />{' '}
              <span className="whitespace-nowrap">
                {t('INDEX_HERO.HEADING_FR_2')} {t('INDEX_HERO.HEADING_FR_3')}
              </span>
              <br />
              <Link href={RedirectUrls.Search} passHref legacyBehavior>
                <StyledDynamicText suppressHydrationWarning>
                  <TypeWritter
                    heroMessages={t('INDEX_HERO.HEADING_FR_ARR', {
                      returnObjects: true
                    })}
                  />
                </StyledDynamicText>
              </Link>
            </h1>
            <p className="mt-[14px] w-full max-w-[467px] text-[15px] font-normal leading-[23.7px] text-black-400 md:text-xl">
              {t('INDEX_HERO.SUBHEADING_FR_1')}{' '}
              <span className="text-nowrap">
                {t('INDEX_HERO.SUBHEADING_FR_2')}
              </span>
            </p>

            <div className="relative mx-0 mb-0 mt-[14px] max-w-[582px] md:mt-[39px] xl:mt-[69px]">
              <CustomAutoComplete
                handleOnSelect={handleOnSearch}
                placeholder={t('INDEX_HERO.SEARCH_PHOLDER')}
              />
            </div>
          </div>
        </div>
        <div className="relative flex size-full justify-center md:w-1/2 md:justify-end md:pt-0 lg:justify-end sm-md-tall:h-1/2">
          <div className="relative mx-auto flex h-full items-end md:aspect-[69/95] lg:-right-9 lg:mr-0 lg:aspect-[inherit] sm-md:aspect-[69/95]">
            <Image
              src={heroImagePhone}
              alt="Imagen de un teléfono con con mapa que muestra los lugares disponibles de almacenaje de SpotMe"
              priority
              height={400}
              blurDataURL={'images/homepage-hero-spotme-thumb.png'}
              className="relative flex max-h-[calc(100%-76px)] overflow-visible object-contain sm:max-h-[calc(100%-84px)] md:h-full md:max-h-[500px] md:w-auto md:object-cover sm-md:h-full sm-md:max-h-[500px] sm-md:w-auto sm-md:object-cover sm-md-tall:max-h-full"
              unoptimized
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default React.memo(Hero)
